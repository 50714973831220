import {useCallback, useState} from 'react';
import {
  Box,
  Link,
  Button,
  Tooltip,
  useTheme,
  Collapse,
  TableRow,
  TextField,
  TableCell,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  Apps,
  FileCopy,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@material-ui/icons';

import {
  ExternalUrlService,
  IUrlComposition,
  IUrlResponse,
  IParametroLinkCustomizado,
  TParametro,
  IPutUrlResponse,
} from 'shared/services/api/ExternalUrlService';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';

interface IProps {
  item: IUrlResponse;
  dataRequest: IUrlComposition;
  copyToClipboard: (uri: string) => void;
  handleOpenQRCode: (uri: string) => void;
  onChange?: () => void;
}

export const AdvancedUrl: React.FC<IProps> = ({
  item,
  dataRequest,
  copyToClipboard,
  handleOpenQRCode,
  onChange,
}) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(item.uri);
  const [disabled, setDisabled] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [parametros, setParametros] = useState<IParametroLinkCustomizado[]>(
    item.parametrosLinkCustomizado,
  );

  const handleChange = (parametro: TParametro, valor: string) => {
    setParametros((prev) =>
      prev.map((param) =>
        param.parametro === parametro ? { ...param, valor } : param
      )
    );
  
    setUrl((prevUrl) => {
      const urlObj = new URL(prevUrl);
      const params = urlObj.searchParams; 

      params.set(parametro, sanitizeString(valor));
  
      return urlObj.toString();
    });
  
    setDisabled(false);
  };

  const sanitizeString = (input: string): string => {
    if (!input) return '';
  
    return input.trim()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .replace(/ç/g, 'c')
                .replace(/Ç/g, 'C');
  };

  const validateRequiredFields = useCallback(() => {
    setHasError(false);
    const requiredFields = parametros.filter(
      (param) =>
        param.obrigatorio && (param.valor === null || param.valor === ''),
    );

    if (requiredFields.length > 0) {
      setHasError(true);
      feedback('Preencha os campos obrigatórios.', 'error');
      return false;
    }

    return true;
  }, [parametros]);

  const handleSave = useCallback(async () => {
    if (!validateRequiredFields()) return;
    try {
      const payload: IPutUrlResponse = {
        idCampanha: dataRequest.idCampanha,
        idComposicaoUri: String(dataRequest.idComposicaoUri),
        idCurso: item.idCurso,
        idEstabelecimento: item.idEstabelecimento,
        idTipoServicoPublicidade: item.idTipoServicoPublicidade,
        parametrosLinkCustomizado: parametros,
      } as IPutUrlResponse;
      await ExternalUrlService.putLinkCustomizado(payload);
      feedback('Dados salvos com sucesso!', 'success');

      onChange && onChange();
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [
    dataRequest.idCampanha,
    dataRequest.idComposicaoUri,
    item.idCurso,
    item.idEstabelecimento,
    item.idTipoServicoPublicidade,
    onChange,
    parametros,
    validateRequiredFields,
  ]);

  const getValue = useCallback(
    (parametro: TParametro) =>
      parametros.find((param) => param.parametro === parametro),
    [parametros],
  );

  return (
    <>
      <TableRow>
        <TableCell>
          <Box display="flex" gridGap={4}>
            <Tooltip title="Copiar URL">
              <IconButton onClick={() => copyToClipboard(url)}>
                <FileCopy />
              </IconButton>
            </Tooltip>
            <Tooltip title="Gerar QR Code">
              <IconButton onClick={() => handleOpenQRCode(url)}>
                <Apps />
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
        {dataRequest.idComposicaoUri !== 1 && (
          <TableCell>{item.estabelecimento}</TableCell>
        )}
        {dataRequest.idComposicaoUri === 3 && (
          <TableCell>{item.curso}</TableCell>
        )}
        <TableCell
          style={{
            maxWidth: '200px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          <Tooltip title="Copiar URL" placement="bottom-start">
            <Link
              color="primary"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                copyToClipboard(url);
              }}>
              {url}
            </Link>
          </Tooltip>
        </TableCell>
        <TableCell width={100} align="center">
          <IconButton
            size="small"
            color="primary"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          padding="none"
          style={{borderBottom: 0}}
          colSpan={
            dataRequest.idComposicaoUri === 1
              ? 3
              : dataRequest.idComposicaoUri === 2
              ? 4
              : 5
          }>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box px={3} py={2}>
              <Typography gutterBottom>
                Colar a URL da página a ser promovida.
              </Typography>
              <Box mb={3} display="flex">
                <TextField
                  fullWidth
                  size="small"
                  color="primary"
                  variant="outlined"
                  label="Link da URL"
                  value={url}
                  inputProps={{
                    style: {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
                <Box mx={2} display="flex" alignItems="center">
                  <IconButton onClick={() => copyToClipboard(url)}>
                    <FileCopy />
                  </IconButton>
                </Box>
              </Box>
              <Typography gutterBottom>
                Informe a origem do tráfego (ex: site ou rede social).
              </Typography>
              <TextField
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                error={
                  hasError &&
                  getValue('utm_source')?.obrigatorio &&
                  !getValue('utm_source')?.valor
                }
                helperText={
                  hasError &&
                  getValue('utm_source')?.obrigatorio &&
                  !getValue('utm_source')?.valor &&
                  'Campo obrigatório'
                }
                label={`Fonte da campanha${
                  getValue('utm_source')?.obrigatorio ? '*' : ''
                }`}
                value={getValue('utm_source')?.valor}
                onChange={(e) => handleChange('utm_source', e.target.value)}
              />
              <Box mb={3} />
              <Typography gutterBottom>
                Diga como você está divulgando a campanha.
              </Typography>
              <TextField
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                label={`Meio de marketing${
                  getValue('utm_medium')?.obrigatorio ? '*' : ''
                }`}
                error={
                  hasError &&
                  getValue('utm_medium')?.obrigatorio &&
                  !getValue('utm_medium')?.valor
                }
                helperText={
                  hasError &&
                  getValue('utm_medium')?.obrigatorio &&
                  !getValue('utm_medium')?.valor &&
                  'Campo obrigatório'
                }
                value={getValue('utm_medium')?.valor}
                onChange={(e) => handleChange('utm_medium', e.target.value)}
              />
              <Box mb={3} />
              <Typography gutterBottom>
                Dê um nome descritivo à sua campanha.
              </Typography>
              <TextField
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                label={`Nome da campanha${
                  getValue('utm_campaign')?.obrigatorio ? '*' : ''
                }`}
                error={
                  hasError &&
                  getValue('utm_campaign')?.obrigatorio &&
                  !getValue('utm_campaign')?.valor
                }
                helperText={
                  hasError &&
                  getValue('utm_campaign')?.obrigatorio &&
                  !getValue('utm_campaign')?.valor &&
                  'Campo obrigatório'
                }
                value={getValue('utm_campaign')?.valor}
                onChange={(e) => handleChange('utm_campaign', e.target.value)}
              />
              <Box mb={3} />
              <Typography gutterBottom>
                Use um código único para rastreamento, no provedor de anúncios.
              </Typography>
              <TextField
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                label={`Identificador de campanha${
                  getValue('utm_id')?.obrigatorio ? '*' : ''
                }`}
                error={
                  hasError &&
                  getValue('utm_id')?.obrigatorio &&
                  !getValue('utm_id')?.valor
                }
                helperText={
                  hasError &&
                  getValue('utm_id')?.obrigatorio &&
                  !getValue('utm_id')?.valor &&
                  'Campo obrigatório'
                }
                value={getValue('utm_id')?.valor}
                onChange={(e) => handleChange('utm_id', e.target.value)}
              />
              <Box mb={3} />
              <Typography gutterBottom>
                Adicione palavras-chave para anúncio.
              </Typography>
              <TextField
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                label={`Termo da campanha${
                  getValue('utm_term')?.obrigatorio ? '*' : ''
                }`}
                error={
                  hasError &&
                  getValue('utm_term')?.obrigatorio &&
                  !getValue('utm_term')?.valor
                }
                helperText={
                  hasError &&
                  getValue('utm_term')?.obrigatorio &&
                  !getValue('utm_term')?.valor &&
                  'Campo obrigatório'
                }
                value={getValue('utm_term')?.valor}
                onChange={(e) => handleChange('utm_term', e.target.value)}
              />
              <Box mb={3} />
              <Typography gutterBottom>
                Utilize para diferenciar conteúdo similar ou links dentro do
                mesmo anúncio.
              </Typography>
              <TextField
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                label={`Conteúdo da campanha${
                  getValue('utm_content')?.obrigatorio ? '*' : ''
                }`}
                error={
                  hasError &&
                  getValue('utm_content')?.obrigatorio &&
                  !getValue('utm_content')?.valor
                }
                helperText={
                  hasError &&
                  getValue('utm_content')?.obrigatorio &&
                  !getValue('utm_content')?.valor &&
                  'Campo obrigatório'
                }
                value={getValue('utm_content')?.valor}
                onChange={(e) => handleChange('utm_content', e.target.value)}
              />
              <Box mt={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={disabled}
                  onClick={handleSave}>
                  Salvar
                </Button>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
